import React from "react"

function Footer() {
    return (
        <footer className="page-footer font-small blue footer">
            <div className="footer-copyright text-center py-3">HKU Msc CS Project - 2024 </div>
        </footer>
    );
}

export default Footer;